<template>
  <div>
    <div class="row">
      <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <b-card
          title="Suresh Giri"
          img-src="https://picsum.photos/600/300/?image=25"
          img-alt="Profile Logo"
          img-top
        >
          <b-button href="#" variant="secondary">Update Image</b-button>
          <b-button href="#" variant="secondary">Change Password</b-button>
        </b-card>
      </div> -->

      <div class="col-xl-12">
        <v-app>
          <div class="kt-portlet">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label w-100 d-flex justify-content-between">
                <h3 class="kt-portlet__head-title">Your Account Information</h3>
                <img :src="countryFlag()"/>

              </div>
            </div>
            <div class="kt-portlet__body">
              <!--begin::Form-->
              <v-form ref="organisation" @submit.stop.prevent="onSubmit" autocomplete="off">
                <v-container class="form-block">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="user.first_name" label="First Name *" outlined dense
                                    autocomplete="off" class="mb-3"></v-text-field>
                      <p class="text-danger font-weight-bold" v-if="$v.user.first_name.$error">
                        Please enter your first name
                      </p>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field v-model="user.last_name" label="Last Name *" outlined dense
                                    autocomplete="off" class="mb-3"></v-text-field>
                      <p class="text-danger font-weight-bold" v-if="$v.user.first_name.$error">
                        Please enter your last name
                      </p>
                    </v-col>

                    <v-col cols="12" md="6"
                           v-if="user.user_name != null && user.user_name.length < 3">
                      <v-text-field v-model="user.user_name" label="Username *" outlined dense
                                    autocomplete="off" class="mb-3"></v-text-field>
                      <p v-if="!$v.user.user_name.isUnique" class="text-danger font-weight-bold">
                        Username already taken
                      </p>

                      <p v-if="$v.user.user_name.$error" class="text-danger font-weight-bold">
                        Please enter username
                      </p>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field v-model="user.phone" label="Mobile *" persistent-hint outlined
                                    dense autocomplete="off" class="mb-3"></v-text-field>

                      <p v-if="$v.user.phone.$error" class="text-danger font-weight-bold">
                        Please enter valid mobile number.
                      </p>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-btn color="primary" @click.prevent="onSubmit" :loading="isLoading"
                             class="pull-right">Update
                      </v-btn>
                      <v-btn color="primary" @click.prevent="openChangeEmailDialog" :loading="isLoading"
                             class="pull-right mr-2">Change Email
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <!--end::Form-->
            </div>
          </div>
          <v-dialog v-model="changeEmailDialog" max-width="450" persistent>
            <v-card>
              <v-card-title>
                Change Email
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Email" dense outlined clearable
                        v-model="new_email"
                        @click:append-outer="changeEmail"
                        :error="$v.new_email.$error?true:false"
                        :append-outer-icon=" 'mdi-send' ">

                    </v-text-field>
                    <span class="text-danger" v-if="$v.new_email.$error">Email is requiered</span>
                  </v-col>
                  <v-col cols="12" v-if="is_email_sent">
                    <v-text-field label="Verification Code" class="mt-3" dense outlined v-model="verification_code"
                                  :append-outer-icon=" 'mdi-check-all' "
                                  :error="$v.verification_code.$error?true:false"
                    >
                    </v-text-field>
                    <span class="text-danger" v-if="$v.verification_code.$error">Email is required</span>
                  </v-col>

                    <v-col cols="12" class="text-center">
                    <v-btn @click="verifyAndChangeEmail" v-if="is_email_sent" :loading="isLoading" depressed class="mr-2" color="blue lighten-2"  dark>
                      Verify
                    </v-btn>

                    <v-btn @click="changeEmailDialog = !changeEmailDialog" depressed class="" color="red lighten-2" dark>
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>


              </v-card-text>
            </v-card>
          </v-dialog>
        </v-app>
        <addresses :addresses="null"></addresses>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user/UserService";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";
import {validationMixin} from "vuelidate";
import {minLength, required, email} from "vuelidate/lib/validators";
import {isMobileNumber} from "@/common/validation";
import Addresses from "./Address";
import {LOGOUT} from "@/store/auth.module";

const userServ = new userService();

export default {
  mixins: [validationMixin],
  name: "account",

  data() {
    return {
      isLoading: false,
      is_email_sent: false,
      changeEmailDialog: false,
      new_email: null,
      verification_code: null,
      user: {
        first_name: "",
        last_name: "",
        phone: "",
        user_name: ""
      }
    };
  },
  components: {
    Addresses
  },
  validations: {
    user: {
      first_name: {
        required,
        minLength: minLength(3)
      },
      last_name: {
        required,
        minLength: minLength(3)
      },

      phone: {
        required,
        minLength: minLength(10),
        isMobileNumber
      }
    },
    new_email: {required, email},
    verification_code: {required}
  },
  methods: {
    openChangeEmailDialog() {
      this.changeEmailDialog = true;
    },

    changeEmail() {
      this.$v.new_email.$touch()
      if (this.$v.new_email.$error) {
        setTimeout(() => {
          this.$v.new_email.$reset()
        }, 3000)
      } else {
        this.isLoading = true;
        userServ.sendChangeEmailMail(this.new_email).then(response => {
          this.isLoading = false;
          if (!response.data.status) {
            this.$snotify.error(response.data.msg)
          } else {
            this.is_email_sent = true;
            this.$snotify.success("Email has been sent to your account")
          }
        }).catch(error => {
          this.isLoading = false
          this.$snotify.error('Something went wrong');
        })
      }
    },
    validateState(name) {
      const {
        $dirty,
        $error
      } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    countryFlag() {
      let countryCode = this.user.country_code.slice(0, -1).toLowerCase();
      return `https://www.countryflags.io/${countryCode}/flat/32.png`;
    },
    onSubmit() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      } else {
        this.isLoading = true;
        ApiService.post("user/update", this.user).then(response => {
          this.$snotify.success("Profile updated");
        }).catch(error => {
          // console.log(error)
        }).finally(() => {
          this.isLoading = false;
        });
      }

      // set spinner to submit button
      // const submitButton = document.getElementById("kt_submit");
      // submitButton.classList.add("kt-spinner");
      //
      // // dummy delay
      // setTimeout(() => {
      //     ApiService.post("user/update", this.user).then(response => {
      //         this.$snotify.success("Profile updated");
      //     });
      //
      //     submitButton.classList.remove("kt-spinner");
      // }, 2000);
    },

    verifyAndChangeEmail(){
      this.$v.verification_code.$touch();
      if (this.$v.verification_code.$error){
        setTimeout(()=>{
          this.$v.$reset();
        }, 3000)
      }else{
        this.isLoading= true
        let data= {email:this.new_email, verification_code:this.verification_code};
        userServ.verifyAndChangeEmail(this.user.id, data).then(response=>{
          this.isLoading= false
            if(!response.data.status){
              this.$snotify.error(response.data.msg)
            }else{
              this.resetEmailChangeDialog();
              this.$snotify.success("Email has been changed successfully")
            }
        }).catch(error=>{
          this.isLoading= false
          this.$snotify.error("Something went wrong")
        })
      }
    },
    resetEmailChangeDialog(){
      this.new_email = null;
      this.verification_code = null;
      this.changeEmailDialog = false;
      this.$v.$reset();
      this.logout();
    },
    logout(){
      this.$snotify.success('Due to the security reason you are going to logout from the system. Please login with your new mail')
      setTimeout(()=>{
        this.$store
            .dispatch(LOGOUT)
            .then(() => this.$router.push({ name: "login" }));
      }, 5000)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Account",
      route: "dashboard"
    }]);
    this.user = this.$store.getters.currentUser;
  },
  computed: {},
  watch: {}
};
</script>
