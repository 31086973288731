<template>
    <div>
        <KTPortlet v-bind:title="'Address'" v-if="addresses ? addresses.length>0 :false">
            <template v-slot:toolbar>
                <v-btn
                    @click="openDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-add-circular-button"></i>
                </v-btn>
                <v-row justify="center"></v-row>
            </template>
            <template v-slot:body>
                <div class="kt-notes">
                    <div class="kt-notes__items">
                        <div class="kt-notes__item" v-for="(address,i) in addresses" :key="address.id">
                            <div class="kt-notes__media">
                <span class="kt-notes__icon">
                  <i class="flaticon-placeholder-3 kt-font-brand"></i>
                </span>
                            </div>
                            <div class="kt-notes__content">
                                <div class="kt-notes__section">
                                    <div class="kt-notes__info">
                                        <a href="#" class="kt-notes__title">{{ address.address_line_1 }}</a>
                                    </div>


                                    <span class="pull-right">
                  <a href="#" @click="editLocation(address)" class="ps-2">
                    <img src="@/assets/media/icons/edit-tools.png" class="edit_icon"/>
                  </a>
                  <a href="#" @click="deleteAddress(address.id)" class="ps-1">
                    <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon"/>
                  </a>
                </span>
                                </div>
                                <span class="kt-notes__body">
                  <div class="row">
                    <div class="col-md-6">
                      <!--                      <br />-->
                        <!--                      {{ address.address_line_1 }},-->
                        <!--                      <br />-->
                      {{ address.address_line_2 }}
                      <br/>
                      {{ address.city }} , {{ address.state }}
                    </div>
                    <div class="col-md-6">
                      <!--                      Lat : {{ address.geo_lat }},-->
                        <!--                      Long : {{ address.geo_long }},-->
                      <!-- <button type="button" class="btn btn-outline-brand btn-elevate btn-pill">
                        <i class="flaticon-placeholder-3"></i> Show On Map
                      </button> -->
                    </div>
                  </div>
                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </KTPortlet>

        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="flaticon-map-location kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">Manage your address</h3>
                        <div class="kt-iconbox__content">
                            <a href="#" @click="openDialog">Add your address</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog scrollable v-model="dialog" persistent max-width="650px">
            <v-card>
                <v-card-title>
                    <span class="headline">Add address</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="model"
                                    :items="items"
                                    :loading="isLoading"
                                    :search-input.sync="search"
                                    hide-no-data
                                    hide-selected
                                    item-text="Description"
                                    item-value="area"
                                    @change="placechange"
                                    outlined
                                    dense
                                    label="Find Your Place"
                                    placeholder="Start typing to Search your place"
                                    prepend-inner-icon="mdi-database-search"
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="4" md="4">
                                <v-text-field
                                    label="Address Line1"
                                    v-model="address.address_line_1"
                                    required
                                    outlined
                                    dense
                                    hint="Road or Tole Name"
                                    persistent-hint
                                ></v-text-field>
                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.address_line_1.$error"
                                >{{validationMessage.address_line_1}}</p>
                            </v-col>
                            <v-col cols="4" md="4">
                                <v-text-field label="Address Line 2" v-model="address.description"
                                              outlined
                                              dense
                                              required></v-text-field>
                            </v-col>
                            <!-- <v-col cols="2" md="2">
                              <v-text-field label="Post Code" v-model="address.post_code" required></v-text-field>
                            </v-col>-->
                            <v-col cols="4" md="4">
                                <v-combobox
                                    v-model="address.area"
                                    :items="areas"
                                    hide-selected
                                    outlined
                                    dense
                                    label="Area"
                                    persistent-hint
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "
                                                    <strong>{{ search }}</strong>". Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.area.$error"
                                >{{validationMessage.area}}</p>
                            </v-col>
                            <v-col cols="4" md="4">
                                <v-combobox
                                    v-model="address.city"
                                    :items="cities"
                                    hide-selected
                                    label="City"
                                    outlined
                                    dense
                                    persistent-hint
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "
                                                    <strong>{{ search }}</strong>". Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.city.$error"
                                >{{validationMessage.city}}</p>
                            </v-col>
                            <v-col cols="4" md="4">
                                <v-combobox
                                    v-model="address.state"
                                    :items="states"
                                    hide-selected
                                    label="State"
                                    outlined
                                    dense
                                    autocomplete="off"
                                    persistent-hint
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "
                                                    <strong>{{ search }}</strong>". Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.state.$error"
                                >{{validationMessage.state}}</p>
                            </v-col>

                            <v-col cols="4" md="4">
                                <v-combobox
                                    v-model="address.type"
                                    :items="addressOptions"
                                    hide-selected
                                    label="Address Type"
                                    autocomplete="off"
                                    outlined
                                    dense
                                    persistent-hint
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "
                                                    <strong>{{ search }}</strong>". Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>

                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.type.$error"
                                >{{validationMessage.type}}</p>
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                          <v-col cols="4" md="4">
                            <v-combobox
                              v-model="address.country_id"
                              :items="countries"
                              hide-selected
                              label="Country"
                              persistent-hint
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      No results matching "
                                      <strong>{{ search }}</strong>". Press
                                      <kbd>enter</kbd> to create a new one
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                        </v-col>-->

                        <!-- <v-col cols="4" md="4">
                            <v-checkbox v-model="showMaps" label="show on Maps?"></v-checkbox>
                        </v-col> -->

                        <v-col cols="12" md="12" v-if="showMaps">
                            <GmapMap
                                style="width: 550px; height: 250px;"
                                :zoom="8"
                                map-type-id="roadmap"
                                :center="center"
                            >
                                <GmapMarker
                                    :position="position"
                                    :clickable="true"
                                    :draggable="true"
                                    v-on:dragend="changePosition($event)"
                                    @click="center=position"
                                />
                            </GmapMap>
                        </v-col>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                    <v-btn class="btn btn-primary" text @click.prevent="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import Swal from "sweetalert2";
    import NotifyService from "@/common/notify.service";
    import userAddressService from "@/services/user/UserAddressService";

    const UserAddressService = new userAddressService();
    export default {
        name: "Locations",
        validations: {
            address: {
                address_line_1: {required},
                city: {required},
                area: {required},
                state: {required},
                type: {required}
            }
        },
        data: () => ({
            addresses: "",
            addressOptions: ["Home", "Office", "Billing", "Shipping"],
            dialog: false,
            search: null,
            edit: false,
            showMaps: false,
            descriptionLimit: 60,
            entries: [],
            isLoading: false,
            model: null,
            center: {lat: 28.3949, lng: 84.124},
            cities: [],
            areas: [],
            states: [],
            countries: [],

            position: {lat: 28.253342268986668, lng: 84.93813695937502},
            markers: [
                {
                    position: {lat: 27.6711212, lng: 85.3446311}
                }
            ],
            place: null,
            validationMessage: {
                address_line_1: "Address 1 is required",
                city: "City is required",
                area: "Area is required",
                state: "State is required",
                type: "Address is required"
            },
            address: {
                address_line_1: "",
                address_line_2: "",
                user_id: "",
                city: "",
                area: "",
                state: "",
                province: "",
                type: "",
                geolocation_id: "",
                geo_lat: "",
                geo_long: ""
            }
        }),
        components: {
            KTPortlet
        },
        mounted() {
            this.getAllUserAddresses();
            this.address.user_id = this.$store.getters.currentUser.id;
        },
        methods: {
            getAllUserAddresses() {
                UserAddressService.getAddressesByUser().then(res => {
                    this.addresses = res.data;
                });
            },
            closeDialog() {
                this.$emit("initOrganisation");
                this.edit = false;
                this.dialog = false;
                this.reset();
            },
            openDialog() {
                this.dialog = true;
                this.edit = false;
            },
            placechange() {
                this.address.city = this.model.city;
                this.address.area = this.model.area;
                this.address.state = this.model.state;
                this.address.province = this.model.province;
                this.address.geolocation_id = this.model.id;
                this.address.geo_lat = parseFloat(this.model.geo_lat);
                this.address.geo_long = parseFloat(this.model.geo_long);
                this.position.lat = parseFloat(this.model.geo_lat);
                this.position.lng = parseFloat(this.model.geo_long);
                this.center = this.position;
            },
            setDescription(description) {
                this.description = description;
            },
            setPlace(place) {
                this.place = place;
            },
            reset() {
                this.model = null;
                this.address = {
                    organisation_id: "",
                    address_line_1: "",
                    address_line_2: "",
                    // post_code: "",
                    city: "",
                    area: "",
                    state: "",
                    province: "",
                    // country_id: "",
                    geolocation_id: "",
                    geo_lat: "",
                    geo_long: "",
                    type: ""
                }
            },
            changePosition(e) {
                this.position.lat = e.latLng.lat();
                this.position.lng = e.latLng.lng();
                this.address.geo_lat = e.latLng.lat();
                this.address.geo_long = e.latLng.lng();
                this.center = this.position;
            },
            usePlace(place) {
                if (this.place) {
                    this.position.lat = this.place.geometry.location.lat();
                    this.position.lng = this.place.geometry.location.lng();
                    this.address.geo_lat = this.place.geometry.location.lat();
                    this.address.geo_long = this.place.geometry.location.lng();
                    this.center = this.position;
                }
                this.place = null;
            },
            editLocation: function (item) {
                this.dialog = true;
                this.edit = true;
                this.address = item;
            },
            getAllAddress() {
                UserAddressService.getAllAddresses().then(res => {

                    this.areas = res.data.areas;
                    this.cities = res.data.cities;
                    this.states = res.data.states;
                });
            },
            save() {
                this.$v.$touch();
                if (this.$v.address.$error) {
                } else {
                    if (!this.edit) {
                        this.saveLocation();
                    } else {
                        this.updateLocation();
                    }
                }
            },
            saveLocation() {
                UserAddressService.create(this.address)
                    .then(res => {
                        this.$emit("initOrganisation");
                        this.dialog = false;
                        this.$snotify.success("Address added");
                        this.getAllUserAddresses();
                    })
                    .catch(err => {

                    });
            },
            cancel() {
                this.$emit("initOrganisation");
                this.dialog = false;
            },
            updateLocation() {
                UserAddressService.update(this.address.id, this.address)
                    .then(res => {
                        this.edit = false;
                        this.closeDialog();
                        this.$emit("initOrganisation");
                        this.dialog = false;
                        this.$snotify.success("Address updated");
                        this.getAllUserAddresses();
                    })
                    .catch(err => {

                    });
            },
            deleteAddress(id) {
                this.$confirm(
                    {
                        message: `Confirm delete address ? `,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                UserAddressService
                                    .delete(id)
                                    .then(res => {
                                        this.getAllUserAddresses();
                                        this.dialog = false;
                                        this.$snotify.success("Address deleted");
                                    })
                                    .catch(err => {

                                    });
                            }
                        }
                    }
                )

            }
        },
        computed: {
            fields() {
                if (!this.model) return [];

                return Object.keys(this.model).map(key => {
                    return {
                        key,
                        value: this.model[key] || "n/a"
                    };
                });
            },
            items() {
                return this.entries.map((entry, index) => {
                    const Description = entry.area + ", " + entry.city + ", " + entry.state;
                    return Object.assign({}, entry, {Description});
                });
            }
        },

        watch: {
            search(val) {
                // Items have already been loaded
                if (this.items.length > 0) return;

                // Items have already been requested
                if (this.isLoading) return;

                this.isLoading = true;

                // Lazily load input items
                // fetch("https://api.publicapis.org/entries")
                UserAddressService.getAddress(val)
                    .then(res => {

                        const {count, result} = res.data;
                        this.count = count;
                        this.entries = result;
                    })
                    .catch(err => {

                    })
                    .finally(() => (this.isLoading = false));
            }
        }
    };
</script>
<style>
    .maps-autocomplete {
        width: 100%;
        border-bottom: 1px solid #3899ec;
    }

    .maps-autocomplete:hover,
    .maps-autocomplete:focus {
        outline: none;
    }
</style>


